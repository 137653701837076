define("discourse/plugins/discourse-6dc-tipper/discourse/components/tip-post-menu-button", ["exports", "@ember/component", "@ember/service", "@ember/object", "discourse/plugins/discourse-6dc-tipper/discourse/components/modal/tip-modal", "@ember/template-factory"], function (_exports, _component, _service, _object, _tipModal, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
      ...attributes
      class="tip-user"
      @action={{action "toggleModal"}}
      @title="tip_icon_alt"
      @icon="hand-holding-usd"
  />
  */
  {
    "id": "1gcjBlVH",
    "block": "[[[8,[39,0],[[17,1],[24,0,\"tip-user\"]],[[\"@action\",\"@title\",\"@icon\"],[[28,[37,1],[[30,0],\"toggleModal\"],null],\"tip_icon_alt\",\"hand-holding-usd\"]],null]],[\"&attrs\"],false,[\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-6dc-tipper/discourse/components/tip-post-menu-button.hbs",
    "isStrictMode": false
  });
  class TipPostMenuButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    init() {
      super.init(...arguments);
      this.show(this.attrs.post.value.user);
    }
    show(user) {
      if (this.currentUser) {
        if (user.id === -1 || user.id === this.currentUser.id) this.set("shouldDisplay", false);
      } else {
        this.set("shouldDisplay", false);
      }
    }
    async toggleModal() {
      this.modal.show(_tipModal.default, {
        model: {
          user: {
            id: this.attrs.post.value.user.id,
            username: this.attrs.post.value.user.username
          }
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleModal", [_object.action]))();
  }
  _exports.default = TipPostMenuButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TipPostMenuButton);
});