define("discourse/plugins/discourse-6dc-tipper/discourse/templates/connectors/user-preferences-nav/wallet-preferences", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="preferences.wallet">
      {{d-icon "credit-card"}}
      <span>{{ i18n "connectors.user_card_below_message_button" }}</span>
  </LinkTo>
  */
  {
    "id": "nlm8byGg",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"preferences.wallet\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"credit-card\"],null]],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,3],[\"connectors.user_card_below_message_button\"],null]],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"d-icon\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-6dc-tipper/discourse/templates/connectors/user-preferences-nav/wallet-preferences.hbs",
    "isStrictMode": false
  });
});